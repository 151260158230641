@font-face {
  font-family: 'SUIT-Medium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'SUIT-Medium';
  src: url('./common/assets/fonts/SUIT-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT-Regular';
  src: url('./common//assets/fonts/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

/* #2b66bd */
:root {
  --color-main: rgb(85, 87, 92);
  --color-main2: #1B1B1B;

  --color-high1: #cec32b;
  --color-high2: #29b6e8;
  --color-high3: #106cae;
  --color-high4: #25285a;

  --color-high-rgb: rgb(0, 198, 207);

  --font-main: 'SUIT-Medium';
  --font-sub: 'SUIT-Regular';

  --border-header: #828590;
  --border-main: #E7E6E8;
  --border-sub: #e4e8eb;
  --border-goo: #fcfcfc;
  --border-result: #e8ebee;

  --js-color: #1a3866;

  --blue-color-1: #ccd9f6;
  --blue-color-2: #aac1f0;

  --chroma-green: rgb(0, 255, 27);

  --font-color-1: rgb(205, 205, 205);

  --height-menu: 45px;

  --box-shadow-b5: 0 2px 5px 1px rgb(64 60 67 / 16%);
  --box-shadow-2: -1px 0 6px rgb(0 0 0 / 20%);
  --box-shadow-3: 0 1px 6px rgb(32 33 36 / 28%);
  --box-shadow-3-hover: 0 1px 6px rgb(0 198 207 / 32%);
  --box-shadow-header-hover: 0 3px 6px rgb(0 198 207 / 32%);
  --box-shadow-b3: 0 4px 5px 0 rgba(0, 0, 0, .12);

  --home-left-width: 800px;
  --home-right-width: 350px;

  --transition-cubic: all 300ms cubic-bezier(.9, 0, .33, 1);
}

.fBlank {
  flex: 1 0;
}