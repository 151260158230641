:root {
  --color-kr1: #21d4fd;
  --color-kr2: #44a9bf;
  --color-kr2-trans: #44a9bf50;
  --color-kr3: #26a;

  --kr-left-width: 225px;
}

.NewsKor1 {
  /* width: 100vw; */

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: dotum, gulim;
}

.nk_con {
  display: flex;
  flex-direction: column;

  width: calc(100vw - 17px);
  max-width: 900px;
}

.nk_con>.head {
  display: flex;
  align-items: center;

  height: 29px;
  margin-bottom: -1px;

  margin-top: 10px;
}

.nk_con>.head>.ib {
  cursor: pointer;
  user-select: none;
}

.nk_con>.head>.fBlank {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  height: calc(100% - 1px);
  margin-bottom: 1px;
  border-bottom: solid 1px #333;
  font-size: 11px;
}

.nk_con>.head>.fBlank>.bb {
  margin-left: 5px;
  cursor: pointer;
  user-select: none;
}

.nk_con>.head>.fBlank>.bb:hover {
  text-decoration: underline;
}

.nk_con>.top {
  display: flex;
  align-items: center;

  width: 100%;
  height: 78px;
  box-sizing: border-box;

  border-top: solid 5px var(--color-kr2-trans);
  border-bottom: solid 3px var(--color-kr2);

  border-left: solid 1px var(--color-kr2-trans);
  border-right: solid 1px var(--color-kr2-trans);

  overflow: hidden;
  padding: 5px 20px;
  margin-bottom: 15px;
}

.nk_con>.top>.promo {
  font-size: 11px;
  color: #666;
  letter-spacing: -.5px;

  cursor: pointer;
  user-select: none;
}

.nk_con>.top>.promo>.p1 {
  font-size: 12px;
  font-weight: 600;
  color: orangered;
  margin-bottom: 3px;
}

.nk_con>.top>.promo>.p1>span {
  font-size: 9px;
}

.nk_con>.top>.promo>.p2>span {
  color: brown;
}

.nk_con>.top>.promo>.p3 {
  font-weight: 600;
}

.nk_con>.top>.promo>.p3>.s1 {
  color: blue;
}

.nk_con>.body {
  display: flex;
  width: 100%;
}

.nk_con>.body>.left {
  display: flex;
  flex-direction: column;

  width: var(--kr-left-width);
  min-width: var(--kr-left-width);
  box-sizing: border-box;

  padding-right: 50px;
}

.nk_con>.body>.left>.title {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;

  margin-bottom: 10px;
  overflow: hidden;
}

.nk_con>.body>.left>.title>img {
  width: 100%;
}

.nk_con>.body>.left>.block {
  display: flex;
  flex-direction: column;

  margin-bottom: 15px;
  color: #333;
}

.nk_con>.body>.left>.b1 {
  background-color: var(--color-kr2-trans);
  padding: 5px;
  /* border-radius: 3px; */
}

.nk_con>.body>.left>.block>.two {
  display: flex;
  align-items: center;
}

.nk_con>.body>.left>.b1 .bb {
  display: flex;
  align-items: center;

  flex: 1 0;
  min-height: 30px;
  box-sizing: border-box;

  font-size: 12px;
  background-color: #fff;
  border-bottom: solid 1px var(--color-kr2-trans);

  cursor: pointer;
  user-select: none;
}

.nk_con>.body>.left>.b1 .bb:hover {
  text-decoration: underline;
}

.nk_con>.body>.left>.block .sq {
  width: 3px;
  height: 3px;
  background-color: var(--color-kr3);

  margin: 0 8px;
}

.nk_con>.body>.left>.b2 {
  background-color: var(--color-kr2-trans);
  padding: 5px;
}

.nk_con>.body>.left>.b2 .bb {
  display: flex;
  align-items: center;

  font-size: 12px;
  height: 20px;
  background-color: #fff;
  margin-bottom: 5px;

  border-radius: 5px;
  padding: 5px;

  cursor: pointer;
  user-select: none;
}

.nk_con>.body>.left>.b2 .bb:hover {
  text-decoration: underline;
}

.nk_con>.body>.left>.b2 .bb.end {
  margin-bottom: 0;
}

.nk_con>.body>.left>.b2 .st {
  font-size: 8px;
  margin-right: 4px;
}

.nk_con>.body>.left>.b3 {
  background-color: var(--color-kr2);
  padding: 5px;
}

.nk_con>.body>.left>.b3>.title {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
}

.nk_con>.body>.left>.b3>.foot {
  display: flex;
  align-items: center;
  margin-top: 5px;
  border: solid 1px var(--border-header);
  border-radius: 3px;
  padding: 3px 8px;

  font-size: 12px;
  color: #333;

  background: rgb(11,23,37);
  background: linear-gradient(0deg, rgba(11,23,37,1) 0%, rgba(222,222,238,1) 1%, rgba(222,222,238,1) 30%, rgba(240,240,238,1) 99%, rgba(11,23,37,1) 100%);

  user-select: none;
  cursor: pointer;
}

.nk_con>.body>.left>.b3>.box {
  background-color: #d6f5fc;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.nk_con>.body>.left>.b3>.box>.bb {
  padding: 5px;
  border-bottom: solid 1px #bbb;

  cursor: pointer;
  user-select: none;
}

.nk_con>.body>.left>.b3>.box>.bb.end {
  padding-bottom: 3px;
  border-bottom: none;
}

.nk_con>.body>.left>.b3>.box>.bb.str {
  padding-top: 3px;
}

.nk_con>.body>.right {
  display: flex;
  flex-direction: column;

  width: calc(100vw - 17px);
  max-width: calc(100% - var(--kr-left-width));
  overflow: hidden;
  box-sizing: border-box;
}

.nk_con>.body>.right>.main {
  font-family: gulim, dotum;
}

.nk_con>.body>.right>.main>.category {
  margin-top: 5px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: solid 1px #ddd;
  font-size: 12px;

  user-select: none;
  cursor: pointer;
}

.nk_con>.body>.right>.main>.category>span {
  margin: 0 10px;
}

.nk_con>.body>.right>.main>.btns {
  display: flex;
  align-items: center;
}

.nk_con>.body>.right>.main>.btns>.btn {
  display: flex;
  align-items: center;
  
  margin-right: 10px;
  margin-bottom: 20px;

  font-size: 12px;

  cursor: pointer;
  user-select: none;
}

.nk_con>.body>.right>.main>.btns>.btn:hover {
  text-decoration: underline;
}

.nk_con>.body>.right>.main>.btns>.btn>img {
  margin-right: 5px;
}

.nk_con>.body>.right>.main>.title {
  font-size: 19px;
  letter-spacing: -.5px;
  font-weight: 600;
  margin-bottom: 15px;

  font-family: dotum, gulim;
}

.nk_con>.body>.right>.main>.editor,
.nk_con>.body>.right>.main>.date {
  font-size: 12px;
  margin-top: 5px;
}

.nk_con>.body>.right>.main>.post {
  margin-top: 15px;
  padding-top: 10px;
  border-top: solid 1px #ddd;
}

/* * -----IMAGEBOX CONTROLLER----- */
.nk_con>.body>.right>.main>.post>.news_imagebox {
  margin-top: 20px;
  margin-bottom: 30px;
}

.nk_con>.body>.right>.main>.post>p {
  font-size: 14px;
  line-height: 1.4;
}

.nk_con>.body>.right>.foot {
  margin: 30px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: solid 1px #ddd;
  font-size: 12px;
}

.nk_con>.body>.right>.foot>.btn {
  display: flex;
  align-items: center;
  margin-right: 10px;

  user-select: none;
  cursor: pointer;
}

.nk_con>.body>.right>.foot>.btn:hover {
  text-decoration: underline;
}

.nk_con>.body>.right>.foot>.btn>img {
  margin-right: 5px;
}

.nk_con>.body>.right>.foot>.btn.end {
  margin-right: 0;
}