:root {
  --color-en1: #c9dfdd;
  --color-en2: #147770;
}

.NewsEng1 {
  /* width: 100vw; */

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Verdana, Arial, Tahoma, sans-serif;
}

.ne1_header {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url('../../../public/images/en1/head.gif') repeat-x right bottom;
  height: 32px;
}

.ne1_header>.head {
  display: flex;
  align-items: center;

  box-sizing: border-box;

  width: calc(100vw - 17px);
  max-width: 900px;
}

.ne1_header>.head>.btn {
  font-size: 13px;
  line-height: 13px;

  padding: 4px 8px;
  border-radius: 2px;

  margin-right: 5px;

  background-color: var(--color-en1);
  color: #000099;

  cursor: pointer;
}

.ne1_header>.head>.btn.selected {
  background-color: var(--color-en2);
  color: #fff;
}

.ne1_body {
  display: flex;
  flex-direction: column;

  width: calc(100vw - 17px);
  max-width: 900px;
}

.ne1_body>.top {
  width: 100%;
  box-sizing: border-box;
}

.ne1_body>.top>img {
  width: 100%;
  user-select: none;
}

.ne1_body>.top>.btns,
.ne1_body>.foot {
  display: flex;
  align-items: center;

  margin-top: -3px;

  font-size: 12px;
  line-height: 12px;
  padding: 0 10px;
  height: 20px;

  background-color: var(--color-en2);
}

.ne1_body>.top>.btns>.bb,
.ne1_body>.foot>.bb {
  color: #fff;
  cursor: pointer;
}

.ne1_body>.top>.btns>.ll,
.ne1_body>.foot>.ll {
  color: var(--color-en1);
  margin: 0 10px;
}

.ne1_body>.main {
  display: flex;
}

.ne1_body>.main>.left {
  background-color: #eee;
  box-sizing: border-box;
  width: 150px;

  font-size: 12px;
}

.ne1_body>.main>.left>img {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ne1_body>.main>.left>.bb,
.ne1_body>.main>.left>.sl {
  display: flex;
  align-items: center;
  height: 20px;
  border-left: solid 7px transparent;
  padding-left: 3px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.ne1_body>.main>.left>.bb.selected,
.ne1_body>.main>.left>.sl.selected {
  background-color: #bbb;
  border-color: var(--color-en2);
}

.ne1_body>.main>.left>.bb {
  color: #000099;
}

.ne1_body>.main>.left>.bold {
  font-weight: 600;
}

.ne1_body>.main>.left>.sl {
  color: #444;
}

.ne1_body>.main>.left>.none {
  cursor: default;
}

.ne1_body>.main>.centre {
  display: flex;
  flex-direction: column;

  flex: 1 0;
  padding: 10px;
  padding-right: 20px;
}

.ne1_body>.main>.centre>.btns {
  display: flex;
  align-items: center;

  font-size: 10px;
  color: #000099;
}

.ne1_body>.main>.centre>.btns>.bb {
  cursor: pointer;
  margin-right: 50px;
}

.ne1_body>.main>.right {
  box-sizing: border-box;
  padding-top: 80px;
  width: 200px;
}

.ne1_body>.main>.right>.box>.tt {
  margin-bottom: 5px;
  font-size: 11px;
  color: #444;
  font-weight: 600;
  padding: 2px 5px;
}

.ne1_body>.main>.right>.box {
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
}

.ne1_body>.main>.right>.box.title>.tt {
  background-color: var(--color-en2);
  color: #fff;
}

.ne1_body>.main>.right>.box.bot {
  padding-bottom: 5px;
  border-bottom: solid 2px var(--color-en2);
}

.ne1_body>.main>.right>.box>.case {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 15px;
  height: 20px;

  color: #000099;
}

.ne1_body>.main .bb:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ne1_body>.main>.right>.box>.case>span {
  width: 8px;
  box-sizing: border-box;
  font-size: 5px;
  height: 15px;
  line-height: 15px;

  color: #000099;
}

.NewsEngBody {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
}

.NewsEngBody>.title {
  font-size: 18px;
  font-weight: 600;

  margin-bottom: 10px;
}

.NewsEngBody>.editor {
  font-size: 11px;
  color: #666;
  font-weight: 600;
  padding-bottom: 19px;
  border-bottom: solid 1px var(--border-header);
  margin-bottom: 15px;
}

.NewsEngBody>.editor>span {
  font-weight: 400;
  color: #444;
}

.NewsEngBody>.body {
  display: flex;
  flex-direction: column;
}

.NewsEngBody>.body>p {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.4;
}

.ne1_body>.foot {
  margin-bottom: 30px;
}