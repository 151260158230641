.Oldgoo {
  font-family: Arial, Helvetica, sans-serif;
}

.OldgooContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.og_login {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 15px;
  line-height: 15px;
  font-size: 13px;

  margin-top: 4px;
  padding-right: 10px;

  color: -webkit-link;
}

.og_login>.bb {
  cursor: pointer;
  text-decoration: underline;
}

.og_login>.ll {
  margin-left: 5px;
  margin-right: 5px;
}

.og_home {
  max-width: 1000px;
  width: calc(100vw - 17px);
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.og_home>.logo {
  width: 300px;
}

.og_home_menu {
  display: flex;
  align-items: center;

  margin-top: 20px;
  padding: 4px;
}

.og_home_menu>.bb {
  height: 15px;
  line-height: 15px;
  font-size: 13px;

  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;

  margin: 0 8px;
}

.og_home_menu>.bb.blacked {
  color: #000;
  text-decoration: none;
}

.og_home_menu>.bb.bold {
  font-weight: 600;
}

.og_serach_box {
  display: flex;
  align-items: center;

  width: 100%;
  height: 42px;
}

.og_serach_box>.main {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: calc(100% / 2);
  height: 100%;
}

.og_serach_box>.main>.top {
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
}

.og_serach_box>.main>.top>input {
  box-sizing: border-box;
  width: 100%;
}

.og_serach_box>.side {
  display: flex;
  flex-direction: column;

  width: calc(100% / 4);
  height: 100%;
}

.og_serach_box>.side>.bb {
  font-size: 10px;
  cursor: pointer;
  color: -webkit-link;
  text-decoration: underline;
}

.og_foot {
  margin-top: 50px;
}

.og_foot>.btns {
  display: flex;
  align-items: center;

  font-size: 13px;
  line-height: 15px;

  color: -webkit-link;
  cursor: pointer;
}

.og_foot>.btns>.bb {
  text-decoration: underline;
}

.og_foot>.btns>.ll {
  margin: 0 4px;
}

.og_foot>.end {
  margin-top: 20px;
  text-align: center;

  font-size: 10px;
}

.og_search {
  max-width: 1000px;
  width: calc(100vw - 17px);
  overflow: hidden;
  box-sizing: border-box;

  margin-top: 4px;
}

.og_search>.head {
  display: flex;
}
.og_search>.head .bb {
  font-size: 13px;
  line-height: 15px;
  height: 15px;

  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.og_search>.head .bb.bold {
  font-weight: 600;
}

.og_search>.head .bb.blacked {
  color: #000;
  text-decoration: none;
}

.og_search .right>.bb {
  font-size: 10px;
  line-height: 11px;
  height: 11px;
}

.og_search>.head>.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 70px;
}

.og_search>.head>.logo>img {
  height: 100%;
}

.og_search>.head>.search {
  display: flex;
  flex-direction: column;
}

.og_search>.head>.search>.top {
  display: flex;
  align-items: center;

  height: 30px;
}

.og_search>.head>.search>.top>.bb {
  margin: 0 10px;
}

.og_search>.head>.search>.bot {
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.og_search>.head>.search>.bot>input {
  width: 300px;
  margin-right: 5px;
}

.og_search>.head>.search>.bot>button {
  margin-right: 5px;
}

.og_search>.body {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
}

.og_search>.body>.line {
  display: flex;
  align-items: center;

  height: 30px;
  box-sizing: border-box;
  padding: 0 5px;

  margin-bottom: 20px;

  border-top: solid 1px -webkit-link;
  background-color: #e9ebf8;
}

.og_search>.body>.line>.tt {
  font-weight: 600;
  font-size: 20px;
}

.og_search>.body>.line>.info {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.og_search>.body>.line>.info>b {
  margin: 0 5px;
}

.og_search>.body>.line>.info>.bb {
  color: -webkit-link;
  text-decoration: underline;
  cursor: pointer;
}

.OgResult {
  display: flex;
  flex-direction: column;

  margin-left: 5px;

  margin-bottom: 20px;
}

.OgResult>.title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;

  width: fit-content;
  max-width: calc(700px - 5px);
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 18px;
  color: -webkit-link;
  text-decoration: underline;

  cursor: pointer;
}

.OgResult>.text {
  width: fit-content;
  max-width: calc(700px - 5px);
  
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;

  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
}

/* .OgResult>.text:hover {
  cursor: pointer;
  text-decoration: underline;
} */

.OgResult>.more {
  max-width: calc(700px - 5px);

  display: flex;
  align-items: center;

  font-size: 14px;
}

.OgResult>.more>.link {
  color: #009933;
}

.OgResult>.more>.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.OgResult>.more>.gb {
  color: gray;
}

.OgResult>.more>.gb.bb {
  cursor: pointer;
  text-decoration: underline;
}

.og_search>.foot {
  width: 700px;

  margin-top: 30px;
  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
}

.og_search>.foot>.page {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
}

.og_search>.foot>.page>.bb {
  font-size: 14px;
  color: -webkit-link;
  text-decoration: underline;
  cursor: pointer;

  margin: 0 10px;
}

.og_search>.foot>.page>.bb.bold {
  font-weight: 600;
}

.og_search>.foot>.page>.bb.blacked {
  color: #000;
  text-decoration: none;
  cursor: default;
}

.og_search>.foot>.btns {
  display: flex;
  align-items: center;

  font-size: 13px;
  line-height: 15px;
  margin-top: 10px;

  color: -webkit-link;
  text-decoration: underline;
}

.og_search>.foot>.btns>.bb {
  flex: 1 0;
  text-align: center;
  cursor: pointer;
}