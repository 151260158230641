:root {
  --color-cn1: #48587c;
  --color-cn2: #c2d2f4;
  --border-cn: #c3c5c4;
  --hover-cn: #551a8b;
}

.NewsChina1 {
  /* width: 100vw; */

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 宋体, Verdana, Arial, Tahoma, sans-serif;
}

.nc_con {
  display: flex;
  flex-direction: column;

  width: calc(100vw - 17px);
  max-width: 800px;
}

.nc_con>.top {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;
  border: solid 2px #551a8b;
  overflow: hidden;

  margin-top: 5px;
  margin-bottom: 5px;
}

.nc_con>.top>img {
  width: 100%;
}

.nc_con>.body {
  display: flex;
  width: 100%;
}

.nc_con>.body>.left {
  display: flex;
  flex-direction: column;

  flex: 1 0;
}

.nc_con>.body>.left>.top {
  display: flex;
  align-items: center;

  padding-left: 10px;
  height: 34px;

  background: url('../../../public/images/cn1/nav_bg.jpg') repeat-x;

  border: solid 1px var(--border-cn);
  border-bottom: solid 4px var(--color-cn1);
}

.nc_con>.body>.left>.top>span {
  font-size: 14px;
  margin: 0 5px;
  cursor: pointer;
}

.nc_con>.body>.left>.top>span:hover {
  color: var(--hover-cn);
  text-decoration: underline;
}

.nc_con>.body>.right {
  display: flex;
  flex-direction: column;

  width: 200px;
  box-sizing: border-box;

  border: solid 1px var(--border-cn);
  background-color: var(--color-cn2);
}

.nc_con>.body>.right>.block {
  display: flex;
  flex-direction: column;
}

.nc_con>.body>.right>.block>.title {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-cn1);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 0;
}

.nc_con>.body>.right>.block>.bb {
  display: flex;
  background-color: var(--color-cn2);
  padding: 3px 0;
}

.nc_con>.body>.right>.block>.bb>.num {
  font-size: 12px;
  color: red;
  min-width: 16px;
  text-align: center;
}

.nc_con>.body>.right>.block>.bb>.btn {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nc_con>.body>.right>.block>.bb>.btn:hover,
.nc_con>.body>.right>.block>.next:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--hover-cn);
}

.nc_con>.body>.right>.block>.next {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 5px;
  font-size: 12px;
  background-color: var(--color-cn2);
}

.nc_con>.body>.right>.block.search {
  align-items: center;
  background-color: var(--color-cn1);
  padding: 10px 0;
}

.nc_con>.body>.right>.block.search>input {
  width: 80%;
  margin-bottom: 5px;
}

.nc_con>.body>.right>.block.search>.check {
  display: flex;
  align-items: center;

  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;

  margin-top: 10px;

  font-size: 12px;
  color: #fff;
}

.nc_con>.body>.right>.block.banner {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  background-color: var(--color-cn2);
}

.nc_con>.body>.right>.block.banner>img {
  cursor: pointer;
}

.nc_con>.body>.right>.block.dog {
  background-color: var(--color-cn2);
  align-items: center;
  padding-bottom: 30px;
}

.nc_con>.body>.right>.block.dog>.top {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 12px;

  width: 80%;
  box-sizing: border-box;

  background-color: #000099;
}

.nc_con>.body>.right>.block.dog>.top>.title {
  color: aqua;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

.nc_con>.body>.right>.block.dog>.top>.mid {
  display: flex;
  align-items: center;
  width: 90%;
}

.nc_con>.body>.right>.block.dog>.top>.mid>input {
  width: 60%;
  margin-right: 5px;
}

.nc_con>.body>.right>.block.dog>.top>.yy {
  margin-top: 5px;
  font-size: 11px;
  padding-bottom: 5px;
  letter-spacing: .5px;
  color: yellow;
}

.nc_con>.body>.right>.block.rec {
  background-color: var(--color-cn2);
  align-items: center;
  font-size: 12px;

  padding-bottom: 50px;
}

.nc_con>.body>.right>.block.rec>.list {
  width: 80%;
  box-sizing: border-box;
  background-color: #fff;
  border: solid 1px #647ca7;
}

.nc_con>.body>.right>.block.rec>.list>img {
  width: 100%;
}

.nc_con>.body>.right>.block.rec>.list>.bb {
  padding: 5px 0;
  color: #0000cc;
  cursor: pointer;
}



/* * post */

.nc_con>.body>.left>.main {
  display: flex;
  flex-direction: column;
  border: solid 1px var(--color-cn2);
}

.nc_con>.body>.left>.main>.title {
  font-size: 20px;
  line-height: 40px;
  padding: 20px;

  color: #333;
  font-weight: 600;
}

.nc_con>.body>.left>.main>.editor {
  font-size: 14px;
  padding-left: 20px;

  width: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 2.5px;

  border-bottom: solid 1px #999;
}

.nc_con>.body>.left>.main>.info {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding: 0 20px;

  font-size: 12px;

  margin-bottom: 12px;
}

.nc_con>.body>.left>.main>.info>.btns {
  cursor: pointer;
}

.nc_con>.body>.left>.main>.post {
  padding: 0 20px;
  font-size: 14px;
  color: #444;
}

.nc_con>.body>.left>.main>.post>p {
  margin-top: 20px;
  margin-bottom: 0;
  line-height: 1.5;
}

.news_imagebox {
  display: flex;

  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}

.news_imagebox.centre {
  align-items: center;
  justify-content: center;
}

.news_imagebox.left,
.news_imagebox.right {
  align-items: flex-start;
}

.news_imagebox.left>img,
.news_imagebox.right>img {
  width: calc(50% - 20px);
  height: fit-content;
  margin-right: 20px;
}

.news_imagebox.left.eng>p,
.news_imagebox.right.eng>p {
  margin: 0;
  font-size: 13px;
  line-height: 1.4;
}

.noMargin {
  margin: 0 !important;
}

.news_imagebox.centre>img {
  box-sizing: border-box;
  width: 50%;
}

.news_imagebox.chn>img {
  border: solid 1px var(--color-cn2);
}

.news_imagebox.eng>img {
  border: solid 1px var(--border-header);
}